import { IFaq } from '../../GlobalState';

export interface FAQState {
  search: string;
  data: IFaq[];
  searchResults: IFaq[];
  active: boolean;
}

export const initialState: FAQState = {
  search: '',
  data: [],
  searchResults: [],
  active: false,
};

export const FAQSearchReducer = (state: FAQState, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.payload,
      };
    case 'SET_DATA':
      return { ...state, data: action.payload };

    case 'SET_SEARCH_RESULTS':
      return {
        ...state,
        searchResults: action.payload,
      };
    default:
      return state;
  }
};

export const handleSearch = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  dispatch: React.Dispatch<any>,
  state: FAQState,
) => {
  const searchInput = event.target.value;
  dispatch({ type: 'SET_SEARCH', payload: searchInput });
  const newArr = state.data
    .filter(
      (item: IFaq) =>
        item?.question?.toLowerCase()?.includes(searchInput.toLowerCase()) ||
        item?.answer?.html?.toLowerCase()?.includes(searchInput.toLowerCase()),
    )
    .map((item: IFaq) => {
      const newTitle = item?.question?.replace(
        new RegExp(searchInput, 'gi'),
        (match: string) =>
          `<mark style="background: #FFAD00; color: rgba(0, 0, 0, 0.95);">${match}</mark>`,
      );
      const newDescription = item?.answer?.html?.replace(
        new RegExp(searchInput, 'gi'),
        (match: string) =>
          `<mark style="background: #FFAD00; color: rgba(0, 0, 0, 0.95);">${match}</mark>`,
      );
      return {
        ...item,
        question: newTitle,
        answer: {
          ...item.answer,
          html: newDescription,
        },
      };
    });
  dispatch({ type: 'SET_SEARCH_RESULTS', payload: newArr });
};
