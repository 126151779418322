import React, { useState } from 'react';
import { styled, useTheme, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '../button';
import { FAQModal } from './faq-modal';
import GlobalGrid from '../GlobalGrid';
import { IFaq } from '../GlobalState';

export interface FAQProps {
  displayFaqs: IFaq[];
  allFaqs: IFaq[];
  handleModalOpen?: () => void;
  handleModalClose?: () => void;
  modalOpen?: boolean;
  title?: string;
  cta?: string;
}

const Container = styled(Box)`
  padding: 3.5rem 2rem;
  margin: 0rem auto 6rem;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(26, 53, 93, 0.45) -14.74%,
    rgba(106, 202, 216, 0.45) 115.05%
  );
  @media (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.values.sm}px) {
    padding: 1.5rem 1rem;
  }
`;

const MainHeader = styled('div')`
  margin-bottom: 2.5rem;
`;

const Heading = styled('span')`
  font-weight: bold;
`;

const Article = styled('div')`
  margin-bottom: 2.5rem;
`;

const Content = styled('div')`
  margin-top: 0.5rem;
`;

const CustomButton = styled(Button)`
  margin-top: 3rem;
  color: ${({ theme }: { theme: Theme }) => theme.palette.greyscale.white};
  border-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.greyscale.white};
`;

const FAQ: React.FC<FAQProps> = ({
  displayFaqs,
  allFaqs,
  handleModalOpen,
  handleModalClose,
  modalOpen,
  title = 'FAQ',
  cta = 'View More',
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <GlobalGrid>
        <Grid item xs >
          <Container>
            <MainHeader>
              <Typography variant="h4">{title}</Typography>
            </MainHeader>
            {displayFaqs?.map((faq: IFaq) => (
              <Article key={faq?.id}>
                <Typography variant="body1">
                  <Heading>{faq?.question}</Heading>
                </Typography>

                {faq?.answer?.html && (
                  <Typography variant="body2" component="div">
                    <Content
                      dangerouslySetInnerHTML={{ __html: faq?.answer?.html }}
                    />
                  </Typography>
                )}
              </Article>
            ))}

            <CustomButton
              variant="outline"
              color="info"
              text={cta}
              theme={theme}
              aria-label={cta}
              onClick={handleModalOpen ?? handleOpen}
            />
          </Container>
        </Grid>
      </GlobalGrid>
      <FAQModal
        open={modalOpen ?? open}
        onClose={handleModalClose ?? handleClose}
        faqs={allFaqs?.filter((faq) => faq?.category?.includes('global'))}
      />
    </>
  );
};

export default FAQ;
