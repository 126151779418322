import React from 'react';
import { styled } from '@mui/material';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import Box from '@mui/material/Box';
import RecommendedProducts from '../../components/RecommendedProducts';
import SEO from '../../components/seo';
import Faq from '../../components/faq/Faq';
import SupportComponent from '../../components/support-center/support-component';
import { useGlobalState, IFaq } from '../../components/GlobalState';
import Layout from '../../components/layout';
import PrivateRoute from '../../components/PrivateRoute';

const Content = styled(Box)`
  max-width: 1440px;
  margin: 0 auto;
`;

const Support: React.FC = () => {
  const [{ purchased }] = useGlobalState();

  if (!purchased || purchased.length < 1) {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    return null;
  }

  const queryResults = useStaticQuery(graphql`
    query SupportFAQ {
      allGraphCmsFaq {
        nodes {
          faqs {
            ... on GraphCMS_FaqComponent {
              id
              category
              question
              answer {
                html
              }
            }
          }
        }
      }
      content: graphCmsDashboard05 {
        titleRecommendedAccessories
        titleFaq
        ctaFaq
      }
    }
  `);
  const faqs = queryResults?.allGraphCmsFaq?.nodes?.[0]?.faqs;
  const supportFaqs = faqs?.filter((faq: IFaq) =>
    faq?.category?.includes('support'),
  );
  const content = queryResults?.content;

  return (
    <PrivateRoute path="/support">
      <Layout>
        <SEO title="Support" description="Support" />
        <Content>
          <SupportComponent />
          <Faq
            displayFaqs={supportFaqs}
            allFaqs={faqs}
            title={content?.titleFaq}
            cta={content?.ctaFaq}
          />
        </Content>
      </Layout>
    </PrivateRoute>
  );
};

export default Support;
