import { styled } from '@mui/material';
import * as React from 'react';
import Input from '../input';
import FAQSingleItem from './faq-single-item';
import Modal, { ModalProps } from '../modal';
import {
  initialState,
  FAQSearchReducer,
  handleSearch,
} from './utils/FAQSearchState';
import { IFaq } from '../GlobalState';

interface FaqModalProps extends ModalProps {
  faqs: IFaq[];
}

const SearchInputContainer = styled('div')`
  margin-right: 3px;
`;

const CustomInput = styled(Input)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const CustomH1 = styled('h1')(({ theme }) => ({
  fontFamily: 'Roboto Mono',
  textTransform: 'uppercase',
  fontSize: '24px',
  lineHeight: '24px',
  marginBottom: theme.spacing(3),
}));

// articles (default questions) --> state.data
// search results for query --> state.searchResults

export const PureFAQModal: React.FC<FaqModalProps> = ({
  open,
  onClose,
  faqs,
}) => {
  const [state, dispatch] = React.useReducer(FAQSearchReducer, initialState);

  React.useEffect(() => {
    dispatch({ type: 'SET_DATA', payload: faqs });
  }, []);

  const defaultQuestions =
    state.data?.length === 0
      ? faqs?.map((faq: IFaq) => (
          <FAQSingleItem
            key={faq?.id}
            question={faq?.question}
            answer={faq?.answer}
            active={state.active}
            hideArrow={false}
          />
        ))
      : state.data?.map((faq: IFaq) => (
          <FAQSingleItem
            key={faq?.id}
            question={faq?.question}
            answer={faq?.answer}
            active={state.active}
            hideArrow={false}
          />
        ));

  const matchedQuestions =
    state.searchResults?.length === 0 ? (
      <FAQSingleItem
        key="no-results-text"
        question="It looks like your search isn't bringing up matches."
        answer={{
          html: '<b>Tip:</b> Try using words that might appear on the page you’re looking for. For example, "print" instead of "printer cartridge instructions.'
        }}
        active={!state.active}
        hideArrow
      />
    ) : (
      state.searchResults?.map((faq: IFaq) => (
        <FAQSingleItem
          key={faq?.id}
          question={faq?.question}
          answer={faq?.answer}
          active={!state.active}
          hideArrow={false}
        />
      ))
    );

  return (
    <Modal open={open} onClose={onClose} style={{ paddingTop: '96px' }}>
      <CustomH1>Frequently Asked Questions</CustomH1>
      <SearchInputContainer>
        <CustomInput
          placeholder="Search FAQ"
          label="Search FAQ"
          showEndAdornments
          onChange={(event) => handleSearch(event, dispatch, state)}
        />
      </SearchInputContainer>
      {state.search?.length >= 3 ? matchedQuestions : defaultQuestions}
    </Modal>
  );
};

export const FAQModal: React.FC<FaqModalProps> = ({ open, onClose, faqs }) => (
  <PureFAQModal faqs={faqs} onClose={onClose} open={open} />
);

export default FAQModal;
