import * as React from 'react';
import {
  Button,
  Stack,
  Typography,
  styled,
  Theme,
  Box,
  Grid,
} from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';
import { useState } from 'react';
import FreshdeskWidget from './freshdesk-widget';

const StyledH4 = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  fontFamily: 'Roboto Mono',
  fontWeight: 'normal',
  fontSize: '24px',
  lineHeight: '36px',
  letterSpacing: '0.01em',
  textTransform: 'uppercase',
}));

const MainButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.greyscale.black,
  backgroundColor: theme.palette.cyan,
  '&:hover, &:focus, &:active': {
    backgroundColor: theme.palette.cyan,
    opacity: '.85',
  },
}));

const StyledBox = styled(Box)`
  padding: 2rem 2rem 4rem 2rem;
  margin: 6rem auto 4rem;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(26, 53, 93, 0.45) -14.74%,
    rgba(106, 202, 216, 0.45) 115.05%
  );
  @media (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.values.sm}px) {
    padding: 1.5rem 1rem;
  }
`;

const Container: React.FC<{ children?: React.ReactNode; title: string }> = ({
  children,
  title,
}) => (
  <Box sx={{ ml: { sm: 3, xs: 2 }, mr: { xs: 2, sm: 3 } }}>
    <StyledBox>
      <Stack>
        <StyledH4>{title}</StyledH4>
        {children}
      </Stack>
    </StyledBox>
  </Box>
);

const SupportComponent: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { content } = useStaticQuery(graphql`
    query DashboardButtonTitles {
      content: graphCmsDashboard05 {
        ctaNewTicket
        titleSupport
      }
    }
  `);

  // This solves the hydration bug reported by Braian where, refreshing the dashboard
  // page makes the support module look funky
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <Container title={content?.titleSupport}>
      <Grid id="support-container" container justifyItems="start" columnGap={2}>
        <MainButton
          variant="contained"
          sx={{ marginTop: 3 }}
          onClick={() => setOpen(!open)}
        >
          {content?.ctaNewTicket}
        </MainButton>
        <FreshdeskWidget open={open} />
      </Grid>
    </Container>
  );
};

export default SupportComponent;
