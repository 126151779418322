import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useGlobalState } from '../GlobalState';
import theme from '../../theme';

const freshworksContainerName = 'freshworks-container';
const freshworksFrameWrapperName = 'freshworks-frame-wrapper';

function getWidgetContainer() {
  return document.getElementById(freshworksContainerName);
}

function waitForElement(elementId: string): Promise<void> {
  return new Promise((resolve) => {
    if (document.getElementById(elementId)) {
      resolve();
    } else {
      const observer = new MutationObserver(() => {
        if (document.getElementById(elementId)) {
          observer.disconnect();
          resolve();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  });
}

function waitForRemoval(elementId: string): Promise<void> {
  return new Promise((resolve) => {
    if (!document.getElementById(elementId)) {
      resolve();
    } else {
      const observer = new MutationObserver(() => {
        if (!document.getElementById(elementId)) {
          observer.disconnect();
          resolve();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  });
}

const FreshdeskWidget = ({ open }: { open: boolean }) => {
  const [{ user }] = useGlobalState();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (typeof window === 'undefined') {
    return null;
  }

  const hideWidgetContainer = () => {
    const container = getWidgetContainer();
    if (container) {
      container.hidden = true;
    }
  };

  const showWidgetContainer = () => {
    const container = getWidgetContainer();
    if (container) {
      container.hidden = false;
    }
  };

  const backgroundLoadWidget = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FreshworksWidget('open');
    hideWidgetContainer();

    const middleName = user?.profile?.name?.middleName;
    const name = `${user?.profile?.name?.givenName}${
      middleName ? ` ${middleName}` : ''
    } ${user?.profile?.name?.familyName}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FreshworksWidget('identify', 'ticketForm', {
      name,
      email: user?.profile?.emails?.[0]?.value,
    });

    waitForElement(freshworksFrameWrapperName).then(() => {
      const widgetFrame = document.getElementById(freshworksFrameWrapperName);
      if (widgetFrame) {
        widgetFrame.style.width = '90%';
        widgetFrame.style.maxWidth = '1400px';
        if (!isMobile) {
          widgetFrame.style.left = '50%';
          widgetFrame.style.transform = 'translateX(-50%)';
        }
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.FreshworksWidget) {
      showWidgetContainer();
      waitForRemoval(freshworksFrameWrapperName).then(() => {
        backgroundLoadWidget();
      });
    }
  }, [open]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fwSettings = {
      widget_id: 69000004363,
    };
    const freshworksWidget = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      freshworksWidget.q.push(arguments);
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    freshworksWidget.q = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FreshworksWidget = freshworksWidget;
    const script = document.createElement('script');
    script.src = 'https://widget.freshworks.com/widgets/69000004363.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    hideWidgetContainer();

    waitForElement(freshworksContainerName).then(() => {
      hideWidgetContainer();
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.FreshworksWidget('hide', 'launcher');
        backgroundLoadWidget();
      }, 1000);
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.FreshworksWidget('close');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.FreshworksWidget('destroy');
      document.body.removeChild(script);
    };
  }, []);

  // nothing is rendered via react
  return null;
};

export default FreshdeskWidget;
