import React, { useState } from 'react';
import { styled, Typography } from '@mui/material';
import { Icon } from '../icon';
import theme from '../../theme';
import { GraphCmsHtml } from '../GlobalState';

const FAQContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(5),
  color: theme.palette.greyscale.white,
}));

const FAQQuestion = styled('div')`
  font-family: 'HCo Gotham SSm';
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0rem;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${theme.palette.greyscale.white};
  cursor: pointer;
`;

const CustomH2 = styled('h2')`
  font-family: 'HCo Gotham SSm';
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

const FAQAnswer = styled(Typography)`
  font-family: 'HCo Gotham SSm';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 0;
  padding-right: 48px;
`;

type FAQSingleItemProps = {
  question: string;
  answer: GraphCmsHtml;
  active: boolean;
  hideArrow: boolean;
};
const FAQSingleItem: React.FC<FAQSingleItemProps> = ({
  question,
  answer,
  active,
  hideArrow,
}) => {
  const [open, setOpen] = useState(false);
  const handleVisibilityToggle = () => setOpen(!open);

  return (
    <FAQContainer>
      <FAQQuestion
        style={{ paddingRight: '20px' }}
        onClick={handleVisibilityToggle}
        role="button"
        aria-expanded={open}
      >
        <CustomH2 dangerouslySetInnerHTML={{ __html: question }} />
        {!hideArrow && (
          <Icon 
            color={theme.palette.greyscale['03']}
            iconName={open ? 'chevron-up' : 'chevron-down'}
          />
        )}
      </FAQQuestion>
      {open || active ? (
        <FAQAnswer dangerouslySetInnerHTML={{ __html: answer?.html }} />
      ) : null}
    </FAQContainer>
  );
};

export default FAQSingleItem;
